import { BaseModel } from "../base.model";
import { SubscriptionStatus } from "~/types/enums/SubscriptionStatus.enum";
import { SubscriptionInterval } from "~/types/enums/SubscriptionInterval.enum";

type SubscriptionInfoConstructorParams = {
  paymentProviderSubscriptionId: string;
  subscriptionExpiryTimestamp: number;
  subscriptionStatus: SubscriptionStatus;
  interval: SubscriptionInterval;
};

export class SubscriptionInfo extends BaseModel {
  paymentProviderSubscriptionId: string;
  subscriptionExpiryTimestamp: number;
  subscriptionStatus: SubscriptionStatus;
  interval: SubscriptionInterval;

  constructor(params: SubscriptionInfoConstructorParams) {
    super();
    this.paymentProviderSubscriptionId = params.paymentProviderSubscriptionId;
    this.subscriptionExpiryTimestamp = params.subscriptionExpiryTimestamp;
    this.subscriptionStatus = params.subscriptionStatus;
    this.interval = params.interval;
  }

  static fromMap(map: any): SubscriptionInfo {
    return new SubscriptionInfo(map);
  }

  toMap(): ModelDatabaseData {
    return {
      paymentProviderSubscriptionId: this.paymentProviderSubscriptionId,
      subscriptionExpiryTimestamp: this.subscriptionExpiryTimestamp,
      subscriptionStatus: this.subscriptionStatus,
      interval: this.interval,
    };
  }
}
