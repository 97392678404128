import { DBFactory } from "classes/database/db_factory";
import { BaseModel } from "../base.model";
import { PackageType } from "~/types/enums/PackageType.enum";
import { SubscriptionInterval } from "~/types/enums/SubscriptionInterval.enum";

export type CapabilityPackages = CapabilityPackage[];

export type CapabilityPackageConstructorParams = {
  id?: string;
  name: string;
  description: string;
  category: string;
  version: string;
  lastUpdatedTimestamp: number;
  capabilities: CapabilitiesConfiguration;
  allowQuantity?: boolean;
  type: PackageType;
  displayOrder: number;
  useCategoryAsId: boolean;
  consumableProductId?: string;
  nonConsumableProducts?: { [key in SubscriptionInterval]: string };
};

export class CapabilityPackage extends BaseModel {
  id?: string;
  name: string;
  description: string;
  category: string;
  version: string;
  lastUpdatedTimestamp: number;
  capabilities: CapabilitiesConfiguration;
  allowQuantity: boolean;
  type: PackageType;
  displayOrder: number;
  useCategoryAsId: boolean;
  consumableProductId?: string;
  nonConsumableProducts: { [key in SubscriptionInterval]: string };

  constructor(params: CapabilityPackageConstructorParams) {
    super();
    this.id = params.id;
    this.name = params.name;
    this.description = params.description;
    this.category = params.category;
    this.version = params.version;
    this.lastUpdatedTimestamp = params.lastUpdatedTimestamp;
    this.capabilities = params.capabilities;
    this.allowQuantity = params.allowQuantity ?? false;
    this.displayOrder = params.displayOrder;
    this.type = params.type ?? PackageType.nonConsumable;
    this.useCategoryAsId = params.useCategoryAsId;
    this.consumableProductId = params.consumableProductId;
    this.nonConsumableProducts = params.nonConsumableProducts ?? ({} as any);
  }

  static fromMap(map: any): CapabilityPackage {
    return new CapabilityPackage(map);
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: `applicationCapabilityDefinitions/${this.version}/packages`,
      path: `applicationCapabilityDefinitions/${this.version}/packages/${this.id}`,
    };
  }

  getProduct(subscriptionInterval: SubscriptionInterval) {
    if (this.type === PackageType.consumable) {
      return this.consumableProductId;
    }

    return this.nonConsumableProducts[subscriptionInterval];
  }

  hasMatchingProductPrice(productId: string) {
    return (
      this.consumableProductId === productId ||
      this.nonConsumableProducts.annually === productId ||
      this.nonConsumableProducts.monthly === productId
    );
  }

  get isConsumable() {
    return this.type === PackageType.consumable;
  }

  async save(merge: boolean = true) {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig, merge);
  }

  async delete() {
    const db = DBFactory.createDatabase();
    await db.delete(this.databaseConfig);
  }
}
