export enum PackageType {
  consumable = "consumable",
  nonConsumable = "non-consumable",
  combined = "combined",
}

export const PackageTypes = [
  {
    value: PackageType.consumable,
    label: "Consumable",
  },
  {
    value: PackageType.nonConsumable,
    label: "Non-Consumable",
  },
];
